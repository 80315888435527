import IconButton from "@mui/material/IconButton";
import orderImagesIc from "../assets/orderImages.svg";
import React, {useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, Typography, useMediaQuery, useTheme} from "@mui/material";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Button from "@mui/material/Button";

export const ReorderImages = ({ onImagesReordered, tempImages }) => {

    const theme = useTheme();
    const [openReorderImagesDialog, setOpenReorderImagesDialog] = useState(false);
    const [tempReorderImages, setTempReorderImages] = useState([]);
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const handleOpenReorderImagesDialog = () => {
        const deepCopyOfTempImages = tempImages.map(img => ({ ...img }));
        setTempReorderImages(deepCopyOfTempImages);
        setOpenReorderImagesDialog(true);
    };

    const handleReorderImagesDialogCloseOutside = (event) => {
        setOpenReorderImagesDialog(false);
    };


    const handleImageLoad = (event) => {
        event.target.classList.add('loaded'); // Add the 'loaded' class to trigger fade-in
    };

    const handleReorderImagesClose = (event) => {
        const deepCopyOfTempReorderImages = tempReorderImages.map(img => ({ ...img }));
        onImagesReordered(deepCopyOfTempReorderImages);
        setOpenReorderImagesDialog(false);
    };

    const onDragEnd = (result) => {
        // Dropped outside the list
        if (!result.destination) {
            return;
        }

        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;

        // Early return if the position is the same
        if (sourceIndex === destinationIndex) {
            return;
        }

        // Creating a new array for manipulation
        const newOrder = Array.from(tempReorderImages);

        // Moving the item within the array
        const [removed] = newOrder.splice(sourceIndex, 1);
        newOrder.splice(destinationIndex, 0, removed);

        // Updating state with the new array
        setTempReorderImages(newOrder);
    };

    return (
        <div>
            {
                tempImages.length>1 && (
                    <IconButton onClick={()=> handleOpenReorderImagesDialog()} className="icon" style={{ width: "36px", height:"36px", display: "flex", alignSelf: "top", marginLeft:"10px" }}>
                        <img src={orderImagesIc} style={{width:"30px", height:"30px"}}/>
                    </IconButton>
                )
            }
            <Dialog open={openReorderImagesDialog} onClose={handleReorderImagesDialogCloseOutside}
                    fullWidth={!isLargeScreen}
                    PaperProps={{
                        style: {
                            minWidth: isLargeScreen ? '500px' : 'auto',
                            maxWidth: isLargeScreen ? '500px' : 'auto',
                        },
                    }}
            >

                <DialogTitle style={{backgroundColor:"white", display:"flex", justifyContent:"center", padding:"none"}}>
                    <span style={{fontSize:"16px", fontWeight:"700px", marginTop:"30px"}}>Changer l'ordre des images</span>
                </DialogTitle>
                <DialogContent style={{backgroundColor:"white"}}>

                    <span style={{lineHeight:"16px"}}>Appuyez longuement pour séléctionner une image, puis glissez la vers sa nouvelle position ✨</span>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable" direction="horizontal">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                        scrollBehavior: "smooth",
                                        overflowX: "auto",
                                    }}
                                >
                                    {tempReorderImages.map((imageUrl, index) => (
                                        <Draggable key={index} draggableId={String(index)} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        ...provided.draggableProps.style,
                                                        display: "flex",
                                                        marginTop: "15px",
                                                        marginLeft: "15px",
                                                        marginBottom: "15px",
                                                        ...(index === tempReorderImages.length - 1 ? { marginRight: "15px" } : {}),
                                                    }}
                                                >
                                                    <img onLoad={handleImageLoad} style={{
                                                        width: "133px",
                                                        height: "200px",
                                                        objectFit: "cover",
                                                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                                                        borderRadius: "15px",
                                                    }} className="fade-in" src={imageUrl.imageLink} alt={`Image ${index + 1}`} />
                                                    {/*{imageUrl.vlink.length > 0 ? (
                                                        <div style={typeIconStyle}>
                                                            <img className="importantwidthheight" style={{ objectFit: "contain", cursor: "pointer" }} src={playIc} alt="Play icon"/>
                                                        </div>
                                                    ) : (
                                                        <div style={typeIconStyle}>
                                                            <img className="importantwidthheight" style={{ objectFit: "contain" }} src={imglogo} alt="Image logo"/>
                                                        </div>
                                                    )}*/}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>


                </DialogContent>
                <DialogActions style={{backgroundColor:"white", marginTop:"0"}}>
                    <div className="button-container" style={{marginBottom:"30px",marginTop:"6px", marginLeft:"30px", marginRight:"30px"}}>
                        <Button onClick={handleReorderImagesClose} className="button-style button-style-noborders" variant="contained">
                            <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                Enregistrer
                            </Typography>
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>

    );
}