import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import firebase from "firebase/compat/app";
import {getBlocDoc, getUserSubscription} from "../firebase";
import {useParams} from "react-router";
import {HeaderHome} from "./headerHome";
import {Skeleton, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {Gallery} from "./carouselpresta";

export default function ViewBloc() {
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [userBloc, setUserBloc] = useState({});

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(async (user) => {
            if (!user) {
                navigate("/");
            } else {
                const subObj = await getUserSubscription(user.uid);
                if (subObj?.status==="trialing" || subObj?.status==="active"){
                    if (id){
                        const userBloc = await getBlocDoc(id);
                        setUserBloc(userBloc);
                        setShowSkeleton(false);
                    } else {
                        setShowSkeleton(false);
                        //todo show something about page expired
                    }
                } else {
                    setShowSkeleton(false);
                    navigateHome();
                }
            }
        });
        // Cleanup the observer when the component is unmounted
        return () => {
            unregisterAuthObserver();
        }
    }, []);

    const navigateHome = () => {
        navigate(-1);
    };


    return (
        <div className="sub-container">
            <HeaderHome></HeaderHome>
            {showSkeleton ?
                (
                    <div className="sub-container-no-top">
                        <div style={{
                            display: "flex", width: "100%", flexDirection: "column",
                            justifyContent: "center", alignContent: "center", alignItems: "center"
                        }}>
                            <Skeleton style={{borderRadius: "15px", minWidth:"150px"}} variant="text" sx={{fontSize: '2rem'}}
                                      width="20%"/>
                            <Skeleton style={{marginTop: "50px", borderRadius: "15px"}} variant="rectangular"
                                      width="100%" height={150}/>
                        </div>
                    </div>
                ) : (
                    <div className="sub-container-no-top">
                        <span className="title-style">{userBloc?.title}</span>
                        <div style={{display:"flex", width:"100%", marginTop:"20px"}}>
                            {userBloc.imageLinks.length>0 ? <Gallery urls={userBloc.imageLinks}>
                            </Gallery> : <Gallery urls={[{
                                imageLink:"https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/image_prestation_par_dfaut.png?alt=media&token=4cb8ec1c-7417-4d93-aa3d-15bc9d0fda0e&_gl=1*1tjyqyy*_ga*OTc3Mjg1MTE0LjE2ODgyMDM2NTU.*_ga_CW55HF8NVT*MTY5NzA0MDcyNy4zMTguMS4xNjk3MDQwODI3LjI4LjAuMA..",
                                vlink:""
                            }]}>
                            </Gallery>}
                        </div>
                        <span style={{fontSize:"16px", marginTop:"20px", display:"flex", width:"100%", whiteSpace:"pre-line", wordWrap: "break-word"}}>
                            {userBloc?.description}
                        </span>

                        <div className="button-container" style={{marginTop:"50px"}}>
                            <Button onClick={()=>(navigate("/modifybloc/"+id))} className="button-style button-style-noborders" variant="contained">
                                <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                    Modifier
                                </Typography>
                            </Button>
                        </div>
                    </div>
                )}
            <div className="sub-container-no-top">
                <div className="button-container" style={{marginTop: "15px"}}>
                    <Button style={{marginBottom: "50px"}} className="button-style-nogr button-style-borders"
                            disableElevation variant="contained" onClick={navigateHome}>
                        <Typography variant="button" style={{textTransform: 'none'}}>
                            Retour
                        </Typography>
                    </Button>
                </div>
            </div>
        </div>
    )
}