import React, {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import {Backdrop, CircularProgress, Skeleton, Typography} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import SnackbarWithCustomBackground from "./snackbar";
import {getAgendaDoc, getPrestaDoc, getRdvById} from "../firebase";
import firebase from "firebase/compat/app";
import 'firebase/firestore'
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import icRightArrow from "../assets/icRightArrow.svg";
import icLeftArrow from "../assets/icLeftArrow.svg";
import {HeaderHome} from "./headerHome";
import {FaMoon} from "react-icons/fa";
import {IOSSwitch} from "./iosswitch";

const months = [
    'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
    'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
];

const daysInMonth = {
    janvier: 31,
    février: 28,
    mars: 31,
    avril: 30,
    mai: 31,
    juin: 30,
    juillet: 31,
    août: 31,
    septembre: 30,
    octobre: 31,
    novembre: 30,
    décembre: 31,
};

const dayNames = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

function formatDayNameAndDate(dayName, dayOfMonth) {
    return `${dayName} ${dayOfMonth}`;
}

function getFormattedDatesForMonth(year, month) {
    const dates = [];
    const today = new Date();
    // Set time to 0 for accurate comparison
    today.setHours(0, 0, 0, 0);

    const daysInMonth = new Date(year, month + 1, 0).getDate();

    for (let day = 1; day <= daysInMonth; day++) {
        const date = new Date(year, month, day);
        // Ensure we're only adding dates after today
        if (date > today) {
            const dayOfWeek = new Intl.DateTimeFormat('fr-FR', { weekday: 'long' }).format(date);
            const formattedDate = `${dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1)} ${day}`;
            dates.push(formattedDate);
        }
    }

    return dates;
}

function transformWorkedDays(daysWorked, currentMonth, currentYear, prestaEndDate, prestaStartDate) {
    const numDaysInMonth = daysInMonth[currentMonth.toLowerCase()];
    const workedDaysFormatted = [];

    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonthIndex = currentDate.getMonth();
    const currentYearValue = currentDate.getFullYear();

    for (let i = 1; i <= numDaysInMonth; i++) {
        const monthIndex = months.indexOf(currentMonth.toLowerCase());

        if (
            (currentYear < currentYearValue) ||
            (currentYear === currentYearValue && monthIndex < currentMonthIndex) ||
            (currentYear === currentYearValue && monthIndex === currentMonthIndex && i < currentDay)
        ) {
            continue; // Skip days earlier than the current date
        }

        const currentDate = new Date(currentYear, monthIndex, i);
        if (currentDate>prestaEndDate || currentDate<prestaStartDate){
            continue;
        }
        const dayNameIndex = new Date(currentYear, monthIndex, i).getDay();
        const dayName = dayNames[dayNameIndex];
        const formattedDay = formatDayNameAndDate(dayName, i);
        if (daysWorked.includes(dayName.toLowerCase())) {
            workedDaysFormatted.push(formattedDay);
        }
    }
    return workedDaysFormatted;
}

export default function AppointDate() {

    const location = useLocation();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();
    const queryParams = new URLSearchParams(location.search);
    const rdvId = queryParams.get('rid');
    //firebase.functions().useEmulator("127.0.0.1", 5001);
    const getFreeTimeSlotsv2break = firebase.functions().httpsCallable('getFreeTimeSlotsv2break');
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect( () => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged( async(user) => {
            setShowBackdrop(true);
            if (!user) {
                setShowBackdrop(false);
                navigate("/");
            } else {
                const fetchData = async () => {
                    try {
                        setBlockButtons(true);
                        const agendaDoc = await getAgendaDoc(user.uid);
                        const prestaData = await getPrestaDoc(id);
                        if (rdvId){
                            try {
                                const rdvObject = await getRdvById(rdvId);
                                if (rdvObject?.isRescheduled){
                                    navigate("/");
                                }
                                setRdvObj(rdvObject);
                            } catch (e) {
                                console.log(e);
                                navigate("/");
                            }
                        }
                        setPrestaObj(prestaData);
                        setPrestaEnd(new Date(prestaData.endTime.seconds * 1000));
                        setPrestaStart(new Date(prestaData.startTime.seconds * 1000));
                        setAgObj(agendaDoc);
                        if (agendaDoc!==null){
                            let daysWorked = [];
                            if (agendaDoc.lundiWorked.checked)  daysWorked.push("lundi");
                            if (agendaDoc.mardiWorked.checked)  daysWorked.push("mardi");
                            if (agendaDoc.mercrediWorked.checked)  daysWorked.push("mercredi");
                            if (agendaDoc.jeudiWorked.checked)  daysWorked.push("jeudi");
                            if (agendaDoc.vendrediWorked.checked)  daysWorked.push("vendredi");
                            if (agendaDoc.samediWorked.checked)  daysWorked.push("samedi");
                            if (agendaDoc.dimancheWorked.checked)  daysWorked.push("dimanche");
                            const formattedWorkedDays = transformWorkedDays(daysWorked, monthNames[currentDate.getMonth()], currentDate.getFullYear(), new Date(prestaData.endTime.seconds * 1000),new Date(prestaData.startTime.seconds * 1000));
                            for (let i = formattedWorkedDays.length - 1; i >= 0; i--) {
                                let day = formattedWorkedDays[i];
                                if (!prestaData.selectedDays.some(selectedDay => day.toLowerCase().includes(selectedDay.toLowerCase()))) {
                                    formattedWorkedDays.splice(i, 1);
                                }
                            }
                            let promises = [];
                            let retainedDays = [];
                            for (let dayStr of formattedWorkedDays){
                                const day = parseInt(dayStr.split(" ")[1]);
                                const month = currentDate.getMonth();
                                const year = currentDate.getFullYear();
                                const utcDate = new Date(Date.UTC(year, month, day));
                                const offsetInMinutes = utcDate.getTimezoneOffset();
                                const offsetInMilliseconds = offsetInMinutes * 60 * 1000;
                                const adjustedDate = new Date(utcDate.getTime() + Math.abs(offsetInMilliseconds));
                                promises.push(getFreeTimeSlotsv2break({ isGoogleSync: agendaDoc.googleAGCode,
                                    prestaDuration: prestaData.prestaDuration, prestaBreak : prestaData.breakDuration, inputDate: adjustedDate, dayStr: dayStr
                                }));
                            }
                            if (promises.length>0){
                                let retained = await Promise.all(promises);
                                for (let retain of retained){
                                    if (retain.data.display){
                                        retainedDays.push(retain.data.dayStr)
                                    }
                                }
                            }
                            setRetainedDays(retainedDays);
                            let arrayOfAllDates = getFormattedDatesForMonth(new Date().getFullYear(), new Date().getMonth());
                            setDateItems(arrayOfAllDates);
                            setDaysWorked(daysWorked);
                            setBlockButtons(false);
                            setShowBackdrop(false);
                        } else {
                            //Redirect user to setup the agenda
                        }
                    } catch (error) {
                        console.error('Error ', error);
                    }
                };
                await fetchData();
                setShowBackdrop(false);
            }
        });
        // Cleanup the observer when the component is unmounted
        return () => {
            unregisterAuthObserver();
        }
    }, []);

    const currentDate = new Date();
    const monthNames = [
        'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
        'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
    ];

    const currentMonthName = monthNames[currentDate.getMonth()];
    const currentDay = currentDate.getDate();
    const dates = [1,3,4,5,7,8,10,11];
    const [daysWorked, setDaysWorked] = useState([]);
    const [showBackdrop, setShowBackdrop] = useState(true);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [prestaEnd, setPrestaEnd] = useState(null);
    const [prestaStart, setPrestaStart] = useState(null);
    const [currentMonthIndex, setCurrentMonthIndex] = useState(currentDate.getMonth());
    const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());
    const [displayMonth, setDisplayMonth] = useState(monthNames[currentDate.getMonth()]);
    const [displayYear, setDisplayYear] = useState(currentDate.getFullYear());
    const [dateItems, setDateItems] = useState([]);
    const [blockButtons, setBlockButtons] = useState(false);
    const [prestaObj, setPrestaObj] = useState({});
    const [agObj, setAgObj] = useState({});
    const [retainedDays, setRetainedDays] = useState([]);
    const [isAllDays, setIsAllDays] = useState(false);
    const [rdvObj, setRdvObj] = useState({});

    const navigatePreviousMonth = async () => {
        setBlockButtons(true);
        setShowBackdrop(true);
        // Calculate previous month and its year
        const previousMonthIndex = (currentMonthIndex - 1 + 12) % 12;
        const previousMonthYear = previousMonthIndex === 11 ? currentYear - 1 : currentYear;
        const previousMonthName = monthNames[previousMonthIndex];

        const realCurrentDate = new Date();
        const realCurrentYear = realCurrentDate.getFullYear();
        const realCurrentMonthIndex = realCurrentDate.getMonth();
        if (previousMonthYear < realCurrentYear || (previousMonthYear === realCurrentYear && previousMonthIndex < realCurrentMonthIndex)) {
            handleOpenSnackbar("Vous ne pouvez pas prendre un RDV pour le mois précédent :)");
            setBlockButtons(false);
            setShowBackdrop(false);
        } else {
            setSelectedItem(null);
            setCurrentMonthIndex(previousMonthIndex);
            setCurrentYear(previousMonthYear);
            setDisplayMonth(previousMonthName);
            setDisplayYear(previousMonthYear);
            const formattedWorkedDays = transformWorkedDays(daysWorked, previousMonthName, previousMonthYear, prestaEnd, prestaStart);
            for (let i = formattedWorkedDays.length - 1; i >= 0; i--) {
                let day = formattedWorkedDays[i];
                if (!prestaObj.selectedDays.some(selectedDay => day.toLowerCase().includes(selectedDay.toLowerCase()))) {
                    formattedWorkedDays.splice(i, 1);
                }
            }
            let promises = [];
            let retainedDays = [];
            for (let dayStr of formattedWorkedDays){
                const day = parseInt(dayStr.split(" ")[1]);
                const month = previousMonthIndex;
                const year = previousMonthYear;
                const utcDate = new Date(Date.UTC(year, month, day));
                const offsetInMinutes = utcDate.getTimezoneOffset();
                const offsetInMilliseconds = offsetInMinutes * 60 * 1000;
                const adjustedDate = new Date(utcDate.getTime() + Math.abs(offsetInMilliseconds));
                promises.push(getFreeTimeSlotsv2break({ isGoogleSync: agObj.googleAGCode,
                    prestaDuration: prestaObj.prestaDuration, prestaBreak : prestaObj.breakDuration, inputDate: adjustedDate, dayStr: dayStr
                }));
            }
            if (promises.length>0){
                try {

                } catch (e) {
                    console.log(e.message);
                    setShowBackdrop(false);
                    setBlockButtons(false);
                    handleOpenSnackbar("Quelque chose s'est mal passé, réessayez ou contactez nous.");
                    return;
                }
                let retained = await Promise.all(promises);
                for (let retain of retained){
                    if (retain.data.display){
                        retainedDays.push(retain.data.dayStr)
                    }
                }
            }

            setRetainedDays(retainedDays);
            let arrayOfAllDates = getFormattedDatesForMonth(previousMonthYear, previousMonthIndex);
            setDateItems(arrayOfAllDates);

            setBlockButtons(false);
            setShowBackdrop(false);
        }

       // setDisplayMonth(previousMonthName);
       // setDisplayYear(previousMonthYear);
    }
    const navigateNextMonth = async () => {
        setBlockButtons(true);
        setShowBackdrop(true);
        setSelectedItem(null);
        const nextMonthIndex = (currentMonthIndex + 1) % 12;
        const nextMonthYear = nextMonthIndex === 0 ? currentYear + 1 : currentYear;
        const nextMonthName = monthNames[nextMonthIndex];

        setCurrentMonthIndex(nextMonthIndex);
        setCurrentYear(nextMonthYear);
        setDisplayMonth(nextMonthName);
        setDisplayYear(nextMonthYear);
        const formattedWorkedDays = transformWorkedDays(daysWorked, nextMonthName, nextMonthYear, prestaEnd, prestaStart);
        for (let i = formattedWorkedDays.length - 1; i >= 0; i--) {
            let day = formattedWorkedDays[i];
            if (!prestaObj.selectedDays.some(selectedDay => day.toLowerCase().includes(selectedDay.toLowerCase()))) {
                formattedWorkedDays.splice(i, 1);
            }
        }
        let promises = [];
        let retainedDays = [];
        for (let dayStr of formattedWorkedDays){
            const day = parseInt(dayStr.split(" ")[1]);
            const month = nextMonthIndex;
            const year = nextMonthYear;
            const utcDate = new Date(Date.UTC(year, month, day));
            const offsetInMinutes = utcDate.getTimezoneOffset();
            const offsetInMilliseconds = offsetInMinutes * 60 * 1000;
            const adjustedDate = new Date(utcDate.getTime() + Math.abs(offsetInMilliseconds));
            promises.push(getFreeTimeSlotsv2break({ isGoogleSync: agObj.googleAGCode,
                prestaDuration: prestaObj.prestaDuration, prestaBreak : prestaObj.breakDuration, inputDate: adjustedDate, dayStr: dayStr
            }));

        }
        if (promises.length>0){
            try {
                let retained = await Promise.all(promises);
                for (let retain of retained){
                    if (retain.data.display){
                        retainedDays.push(retain.data.dayStr)
                    }
                }
            } catch (e){
                console.log(e.message);
                setShowBackdrop(false);
                setBlockButtons(false);
                handleOpenSnackbar("Quelque chose s'est mal passé, réessayez ou contactez nous.")
                return;
            }

        }

        setRetainedDays(retainedDays);
        let arrayOfAllDates = getFormattedDatesForMonth(nextMonthYear, nextMonthIndex);
        setDateItems(arrayOfAllDates);

        setBlockButtons(false);
        setShowBackdrop(false);
        /*setDisplayMonth(nextMonthName);
        setDisplayYear(nextMonthYear);*/
    }

    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    const navigateBack = async () => {
        navigate("/prestab/"+id);
    }

    const navigateToAppointDay = async () => {
        if (selectedItem!=null && isAllDays){
            navigate("/appointday/"+id+`?choice=${dateItems[selectedItem]}&month=${displayMonth}&year=${displayYear}${rdvId ? `&rdvId=${rdvId}` : ''}`,
                {state:
                        {
                            choice:dateItems[selectedItem],
                            month:displayMonth,
                            year:displayYear,
                            rdvId: rdvId ? rdvId : ''
                        }
                });
        } else if (selectedItem!=null && !isAllDays) {
            navigate("/appointday/"+id+`?choice=${retainedDays[selectedItem]}&month=${displayMonth}&year=${displayYear}${rdvId ? `&rdvId=${rdvId}` : ''}`,
                {state:
                        {
                            choice:retainedDays[selectedItem],
                            month:displayMonth,
                            year:displayYear,
                            rdvId: rdvId ? rdvId : ''
                        }
                });
        } else {
            handleOpenSnackbar('Vous devez séléctionner une date pour continuer.');
        }

    }

    const [selectedItem, setSelectedItem] = useState(null);

    const handleCardClick = (index) => {
        setSelectedItem((prevSelected) => (prevSelected === index ? null : index));
    };

    const handleEnrichSlots = async () => {
        setSelectedItem(null);
        setIsAllDays(!isAllDays);
    }

    return (
        <div className="sub-container">
            <HeaderHome></HeaderHome>
            <div className="sub-container-no-top">
                <span className="title-style">Séléctionner une date</span>
                <div style={{width:"100%", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", marginTop:"10px"}}>
                    <IconButton disabled={blockButtons}
                                onClick={() => navigatePreviousMonth()}
                                variant="contained" style={{ color:"white", display:"flex"}}>
                        <img src={icLeftArrow} style={{width:"40px", height:"40px"}}/>
                    </IconButton>
                    <div style={{fontWeight:"700", display:"flex", alignContent:"center", alignItems:"center", justifyContent:"center", textAlign:"center"}}>
                        {displayMonth} {displayYear}
                    </div>
                    <IconButton disabled={blockButtons} onClick={() => navigateNextMonth()}
                                 variant="contained" style={{ color:"white", display:"flex"}}>
                        <img src={icRightArrow} style={{width:"40px", height:"40px"}}/>
                    </IconButton>
                </div>

                <div style={{flexDirection:"column", display:"flex", background:"#F9F3FF", borderRadius:"15px", marginTop:"20px", marginBottom:'10px', width:"100%"}}>
                    <div className="field-container" style={{marginTop:"16px", marginLeft:"16px", marginRight:"16px", width:'fit-content'}}>
                        <div className="title-field-container">
                                  <span className="text-field-style">
                                      Afficher les jours non travaillés
                                  </span>
                        </div>
                        <div style={{width:"100%"}}>
                            <span>Les jours où vous ne travaillez pas normalement pourront être sélectionnés.</span>
                        </div>
                    </div>
                    <div style={{ marginTop:"10px" , marginBottom:"0px", width:"100%", display:"flex", flexDirection:"row", justifyContent:"start", alignItems:"center", margin:"16px"}}>

                        <IOSSwitch
                            checked={isAllDays}
                            onChange={(event) => handleEnrichSlots(event)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />

                        {isAllDays ? <span style={{marginLeft:"20px", color:"black"}}>Activé</span>
                            : <span style={{marginLeft:"20px", color:"black"}}>Desactivé</span>}

                    </div>
                </div>

                {showBackdrop && (
                    <div style={{display:"flex", width:"100%", flexDirection:"column",
                        justifyContent:"center", alignContent:"center", alignItems:"center"}}>
                        <Skeleton style={{marginTop:"30px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                        <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                        <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                    </div>
                )}

                {!showBackdrop && (
                    <div className="date-layout">
                        {
                            isAllDays ? (
                                dateItems.map((date, index) => (
                                        <div key={index}
                                             className="date-card"
                                             onClick={() => handleCardClick(index)}

                                             style={{background:
                                                     selectedItem === index
                                                         ? "linear-gradient(100deg, #FF0844 0%, #4808FF 100%)"
                                                         : retainedDays.includes(date) ? "white" : "#F9F3FF",
                                                 color: selectedItem === index
                                                     ? "white"
                                                     : "black",
                                                 cursor:"pointer"
                                             }}
                                        >
                                            <div style={{margin:"20px"}}>
                                                {date}
                                            </div>
                                        </div>
                                    ))
                            ) : (
                                retainedDays.map((date, index) => (
                                        <div key={index}
                                             className="date-card"
                                             onClick={() => handleCardClick(index)}

                                             style={{background:
                                                     selectedItem === index
                                                         ? "linear-gradient(100deg, #FF0844 0%, #4808FF 100%)"
                                                         : retainedDays.includes(date) ? "white" : "#F9F3FF",
                                                 color: selectedItem === index
                                                     ? "white"
                                                     : "black",
                                                 cursor:"pointer"
                                             }}
                                        >
                                            <div style={{margin:"20px"}}>
                                                {date}
                                            </div>
                                        </div>
                                    ))
                            )
                        }
                    </div>
                )}


                    {!isAllDays && retainedDays.length===0 && !showBackdrop && (
                        <div className="date-card" style={{width:"100%"}}>
                            <div style={{margin:"20px"}}>
                                <span>Aucune date disponible ce mois</span>
                            </div>
                        </div>
                    )}

                <div className="button-container">
                    <Button onClick={navigateToAppointDay} className="button-style button-style-noborders" variant="contained">
                        <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                            Continuer
                        </Typography>
                    </Button>
                </div>

                <div className="button-container" style={{marginTop:"15px"}}>
                    <Button style={{marginBottom:"100px"}} className="button-style-nogr button-style-borders" disableElevation variant="contained" onClick={navigateBack}>
                        <Typography variant="button" style={{ textTransform: 'none' }}>
                            Annuler
                        </Typography>
                    </Button>
                </div>
                <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar} message={snackMessage} style={{width:"100%"}} />

            </div>
        </div>
    )

}