import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {
    Backdrop,
    Chip, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Skeleton,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import firebase from "firebase/compat/app";
import {HeaderHome} from "./headerHome";
import icDiamond from "../assets/diamondColored.svg";
import icLock from "../assets/icLock.svg";
import {getUserBlocs, getUserSubscription, updateSortIndexes} from "../firebase";
import diamondIc from "../assets/diamondColored.svg";
import SnackbarWithCustomBackground from "./snackbar";
import icSortHome from "../assets/icSortHome.svg";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {SomethingWentWrong} from "./somethingWentWrong";

export default function Bloc() {
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [isSub, setIsSub] = useState(false);
    const [openReorderBlocDialog, setOpenReorderBlocDialog] = useState(false);
    const [userBlocs, setUserBlocs] = useState([]);
    const [tempBlocArray, setTempBlocArray] = useState([]);
    const [isError, setIsError] = useState(false);

    const handleImageLoad = (event) => {
        event.target.classList.add('loaded'); // Add the 'loaded' class to trigger fade-in
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(async (user) => {
            if (!user) {
                navigate("/");
            } else {
                const fetchData = async () => {
                    try {
                        const subObj = await getUserSubscription(user.uid);
                        if (subObj?.status==="trialing" || subObj?.status==="active"){
                            const blocsArray = await getUserBlocs(user.uid);
                            setUserBlocs(blocsArray);
                            setIsSub(true);
                            setShowSkeleton(false);
                        } else {
                            setIsSub(false);
                            setShowSkeleton(false);
                        }
                    } catch (e) {
                        console.error(e);
                        handleOpenSnackbar("Quelque chose s'est mal passé, actualisez la page ou contactez nous.");
                        showSkeleton(false);
                        setIsError(true)
                    }
                }
                fetchData();
            }
        });
        // Cleanup the observer when the component is unmounted
        return () => {
            unregisterAuthObserver();
        }
    }, []);
    const navigateHome = () => {
        navigate("/home");
    };

    const navigateToViewBloc = (id) => {
        navigate("/viewbloc/"+id);
    };

    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    const handleReorderBlocDialogCloseOutside = (event) => {
        setOpenReorderBlocDialog(false);
    };

    useEffect(() => {
        if (openReorderBlocDialog){
            setTempBlocArray(userBlocs);
        }
    }, [openReorderBlocDialog]);

    const onDragEnd = (result) => {
        // Dropped outside the list
        if (!result.destination) {
            return;
        }

        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;

        // Early return if the position is the same
        if (sourceIndex === destinationIndex) {
            return;
        }
        let newOrder = Array.from(tempBlocArray.filter((p)=>p.isBlocPublished===true)
            .sort((a,b)=>{
                // Handle cases where isSorted doesn't exist or is false
                if (!a.isSorted || !b.isSorted) {
                    if (!a.isSorted && b.isSorted) return 1; // b is sorted, a is not
                    if (a.isSorted && !b.isSorted) return -1; // a is sorted, b is not

                    // Both are not sorted, sort by timestamp in descending order
                    return new Date(b.timestamp.seconds * 1000) - new Date(a.timestamp.seconds * 1000);
                }
                // Both are sorted, compare by index
                return a.sortIndex - b.sortIndex;
            }));
        // Moving the item within the array
        const [removed] = newOrder.splice(sourceIndex, 1);
        newOrder.splice(destinationIndex, 0, removed);
        newOrder = newOrder.map((item, index) => ({
            ...item,
            isSorted: true,
            sortIndex: index
        }));

        // Create a map of the updated fields
        const newOrderMap = new Map(
            newOrder.map((item) => [item.documentId, { isSorted: item.isSorted, sortIndex: item.sortIndex }])
        );

        // Update the original prestaArray with the new `isSorted` and `index` values
        const updatedBlocArray = userBlocs.map((item) =>
            newOrderMap.has(item.documentId)
                ? { ...item, ...newOrderMap.get(item.documentId) } // Only update isSorted and index
                : item // Keep unchanged for non-matching
        );

        setTempBlocArray(updatedBlocArray);
    };

    const handleReorderBlocClose = async () => {
        setShowBackdrop(true);
        setOpenReorderBlocDialog(false);
        const dataToUpdate = tempBlocArray
            .filter(item => item.isSorted === true && item.sortIndex !== undefined)
            .map(item => ({
                documentId: item.documentId,
                isSorted: item.isSorted,
                sortIndex: item.sortIndex
            }));

        try {
            await updateSortIndexes(dataToUpdate, true);
            setShowBackdrop(false);
        } catch (e) {
            handleOpenSnackbar("Quelque chose s'est mal passé. Contactez nous. ", e.message);
            setShowBackdrop(false);
            console.error('Error', e.message);
        }
        setUserBlocs(tempBlocArray);
    }

    if (isError){
        return (
            <SomethingWentWrong></SomethingWentWrong>
        )
    }

    return (
        <div className="sub-container">
            <HeaderHome></HeaderHome>
            {showSkeleton ?
                (
                    <div className="sub-container-no-top">
                        <div style={{
                            display: "flex", width: "100%", flexDirection: "column",
                            justifyContent: "start", alignContent: "center", alignItems: "center", minHeight:"300px"
                        }}>
                            <Skeleton style={{borderRadius: "15px", minWidth:"150px"}} variant="text"
                                      sx={{fontSize: '2rem'}}
                                      width="20%"/>
                            <Skeleton style={{marginTop: "30px", borderRadius: "60px"}} variant="rectangular"
                                      width="100%" height={71}/>
                            <Skeleton style={{marginTop: "80px", borderRadius: "15px"}} variant="rectangular"
                                      width="100%" height={104}/>
                            <Skeleton style={{marginTop: "15px", borderRadius: "15px"}} variant="rectangular"
                                      width="100%" height={104}/>
                        </div>
                    </div>
                ) : (
                    !isSub ? (
                        <div className="sub-container-no-top">
                                <span className="title-style">Bloc d'infos</span>

                                <div className="button-container">
                                    <Button className="button-style-noborders grey-locked-button" variant="contained"
                                            endIcon={<img src={icLock}></img>}
                                            onClick={()=>handleOpenSnackbar("Cette fonctionnalité est disponible uniquement avec faismoibg premium")}
                                    >
                                        <Typography variant="button" style={{textTransform: 'none', color: '#FFFFFF'}}>
                                            Créér un bloc d'info
                                        </Typography>
                                    </Button>
                                </div>

                                <div style={{display:"flex", borderRadius:"15px", width:"100%",
                                    boxShadow: "0px 4px 15px 0px rgba(218, 224, 229, 0.9)",
                                lineHeight:"20px", marginTop:"20px"}}>
                                    <span style={{marginBottom: "20px", marginLeft:"20px", marginRight:"20px",marginTop:"15px"}}>
                                        Cette fonctionnalité n’est pas disponible en forfait gratuit, nous vous invitons à passer au forfait Premium pour pouvoir l’utiliser.
                                    </span>
                                </div>
                                <div style={{width:"100%", justifyContent:"center", display:"flex", marginBottom:"40px"}}>
                                    <Chip onClick={()=>navigate("/subscription")}
                                          icon={<img style={{width:"16px", height:"16px"}} src={icDiamond}></img>}
                                          style={{marginTop:"20px", background:"white", cursor:"pointer", border:"1px solid black",
                                              color:"black", fontWeight:"400", padding:"18px 18px", fontSize:"14px", borderRadius:"1000px"}}
                                          label="Faismoibg premium">
                                    </Chip>
                                </div>
                                <div className="flex-direction height-anticipation-screen" style={{display:"flex", width:"100%", alignItems:"center", alignContent:"center", justifyContent:"center"}} >
                                    <img onLoad={handleImageLoad} width={300}
                                         className="fade-in" src="https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/NODELETE%2Fbloc%20infos%201.png?alt=media&token=08a121f9-2e10-4138-b4cf-4adefc39e39c"/>
                                    <img onLoad={handleImageLoad} width={300}
                                         className="fade-in" src="https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/NODELETE%2Fbloc%20infos%202.png?alt=media&token=97564661-0848-4d17-a2d4-46e6ea6f7fee"/>
                                    <img onLoad={handleImageLoad} width={300}
                                         className="fade-in" src="https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/NODELETE%2Fbloc%20infos%203.png?alt=media&token=e02e15d5-4517-4876-88fa-eed5729cc52b"/>
                                </div>
                                <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar}
                                                              message={snackMessage} style={{width: "100%"}}/>
                            </div>
                        )
                        : (
                            <div className="sub-container-no-top">
                                <span className="title-style">Bloc d'infos</span>
                                <div className="button-container">
                                    <Button className="button-style-noborders client-stats-button" variant="contained"
                                            onClick={()=>navigate('/createbloc')}
                                    >
                                        <Typography variant="button" style={{textTransform: 'none', color: '#FFFFFF'}}>
                                            Créer un bloc d'info
                                        </Typography>
                                    </Button>
                                </div>
                                {userBlocs.length<0 && (
                                    <span style={{marginTop:"20px"}}>
                                        Aucun bloc d’informations existant
                                    </span>
                                )}
                                <div style={{width:"100%", justifyContent:"center", display:"flex"}}>
                                    <Chip onClick={()=>setOpenReorderBlocDialog(true)}
                                          icon={<img alt="sortic" style={{width:"16px", height:"16px"}} src={icSortHome}></img>}
                                          style={{marginTop:"30px", background:"white", cursor:"pointer", border:"1px solid black",
                                              color:"black", fontWeight:"400", padding:"8px 10px", fontSize:"14px"}}
                                          label="Ordre des blocs d’infos">
                                    </Chip>
                                </div>
                                <div style={{display:"flex", flexDirection:"column", width:"100%"}}>
                                    {userBlocs && userBlocs.sort((a,b)=>{
                                        if (a.isBlocPublished === true && b.isBlocPublished === true) {
                                            // Handle cases where isSorted doesn't exist or is false
                                            if (!a.isSorted || !b.isSorted) {
                                                if (!a.isSorted && b.isSorted) return 1; // b is sorted, a is not
                                                if (a.isSorted && !b.isSorted) return -1; // a is sorted, b is not

                                                // Both are not sorted, sort by timestamp in descending order
                                                return new Date(b.timestamp.seconds * 1000) - new Date(a.timestamp.seconds * 1000);
                                            }
                                            // Both are sorted, compare by index
                                            return a.sortIndex - b.sortIndex;
                                        } else if (a.isBlocPublished === true && b.isBlocPublished === false) {
                                            return -1; // 'a' comes first because it is in "production"
                                        } else if (a.isBlocPublished === false && b.isBlocPublished === true) {
                                            return 1; // 'b' comes first because it is in "production"
                                        } else {
                                            return new Date(b.timestamp.seconds * 1000) - new Date(a.timestamp.seconds * 1000); // Sort in descending order
                                        }

                                    }).map((bloc, index) => (
                                        <div
                                            key={index}
                                            onClick={()=>(navigateToViewBloc(bloc.documentId))}
                                            style={{
                                                cursor: "pointer",
                                                boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                                                background: "white",
                                                marginTop: index===0 ? "25px" : "10px",
                                                borderRadius: "15px",
                                                marginBottom: "16px",
                                                display: "flex",
                                                width: "100%"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    marginTop: "15px",
                                                    marginLeft: "15px",
                                                    marginBottom: "15px",
                                                    alignItems: "center",
                                                    margin: "12px"
                                                }}
                                            >
                                                <img
                                                    onLoad={handleImageLoad}
                                                    style={{
                                                        width: "80px",
                                                        height: "80px",
                                                        objectFit: "cover",
                                                        borderRadius: "10px",
                                                    }}
                                                    className="fade-in"
                                                    src={
                                                        bloc?.imageLinks && bloc?.imageLinks?.length > 0 && bloc?.imageLinks[0]?.imageLink?.length > 0
                                                            ? bloc?.imageLinks[0]?.imageLink
                                                            : "https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/image_prestation_par_dfaut.png?alt=media&token=4cb8ec1c-7417-4d93-aa3d-15bc9d0fda0e&_gl=1*1tjyqyy*_ga*OTc3Mjg1MTE0LjE2ODgyMDM2NTU.*_ga_CW55HF8NVT*MTY5NzA0MDcyNy4zMTguMS4xNjk3MDQwODI3LjI4LjAuMA.."
                                                    }
                                                    alt={`Bloc image ${index}`}
                                                />

                                                <div style={{ display: "flex", height: "100%", flexDirection: "column" }}>
                                                    <span
                                                        style={{
                                                            display: "-webkit-box",
                                                            WebkitBoxOrient: "vertical",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            WebkitLineClamp: 2,
                                                            fontWeight: 600,
                                                            marginLeft: "16px",
                                                            marginTop: "6px",
                                                            placeSelf: "start",
                                                            alignSelf: "start"
                                                        }}
                                                    >
                                                      {bloc?.title}
                                                    </span>

                                                    <div style={{ display: "flex", marginTop: "16px", marginLeft: "16px" }}>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                padding: "5px 10px",
                                                                alignItems: "center",
                                                                gap: "10px",
                                                                borderRadius: "99px",
                                                                background: !bloc?.isBlocPublished
                                                                    ? "linear-gradient(90deg, #8A9699 0%, #434849 100%)"
                                                                    : "linear-gradient(90deg, #06AC2C 0%, #28D850 100%)"
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    color: "white",
                                                                    fontSize: "14px",
                                                                    fontStyle: "normal",
                                                                    fontWeight: "400",
                                                                    lineHeight: "normal"
                                                                }}
                                                            >
                                                              {!bloc?.isBlocPublished ? "Brouillon" : "Publié"}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )
                )}
            <div className="sub-container-no-top">
                <div className="button-container" style={{marginTop: "40px"}}>
                    <Button style={{marginBottom: "50px"}} className="button-style-nogr button-style-borders"
                            disableElevation variant="contained" onClick={navigateHome}>
                        <Typography variant="button" style={{textTransform: 'none'}}>
                            Retour
                        </Typography>
                    </Button>
                </div>
            </div>
            <div style={{position:"relative"}}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={showBackdrop}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
            <Dialog open={openReorderBlocDialog} onClose={handleReorderBlocDialogCloseOutside}
                    fullWidth={!isLargeScreen}
                    PaperProps={{
                        style: {
                            minWidth: isLargeScreen ? '500px' : 'auto',
                            maxWidth: isLargeScreen ? '500px' : 'auto',
                        },
                    }}
            >

                <DialogTitle style={{backgroundColor:"white", display:"flex", justifyContent:"center", paddingTop:"16px",
                    paddingBottom:"1px", paddingLeft:"24px", paddingRight:"24px", flexDirection:"column"}}>
                    <span style={{fontSize:"16px", fontWeight:"700px", marginTop:"20px", alignSelf:"center"}}>Changer l'ordre des blocs</span>
                    <span style={{lineHeight:"21px", marginBottom:"5px", display:"flex",fontSize:"16px", fontWeight:400, marginTop:"15px"}}>Appuyez longuement pour séléctionner un bloc, puis glissez la vers sa nouvelle position ✨<br/>Seul les blocs publiés peuvent être réorganisés</span>
                </DialogTitle>
                <DialogContent style={{backgroundColor:"white"}}>

                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable" direction="vertical">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "100%",
                                        scrollBehavior: "smooth",
                                        overflowX: "visible",
                                        overflowY: "visible"
                                    }}
                                >
                                    {tempBlocArray.filter((e)=> e.isBlocPublished===true)
                                        .sort((a,b)=>{
                                            // Handle cases where isSorted doesn't exist or is false
                                            if (!a.isSorted || !b.isSorted) {
                                                if (!a.isSorted && b.isSorted) return 1; // b is sorted, a is not
                                                if (a.isSorted && !b.isSorted) return -1; // a is sorted, b is not

                                                // Both are not sorted, sort by timestamp in descending order
                                                return new Date(b.timestamp.seconds * 1000) - new Date(a.timestamp.seconds * 1000);
                                            }
                                            // Both are sorted, compare by index
                                            return a.sortIndex - b.sortIndex;
                                        })
                                        .map((bloc, index) => (
                                            <Draggable key={index} draggableId={String(index)} index={index}>
                                                {(provided, snapshot) => (
                                                    <div style={{display:"flex", flexDirection:"column", width:"100%",
                                                        ...provided.draggableProps.style,
                                                        ...(index === tempBlocArray.length - 1 ? { marginRight: "15px" } : {}),
                                                    }}
                                                         ref={provided.innerRef}
                                                         {...provided.draggableProps}
                                                         {...provided.dragHandleProps}
                                                    >
                                                        <div style={{boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                                                            background:"white",
                                                            marginTop: index === 0 ? "16px" : "0px",
                                                            borderRadius:"15px",
                                                            marginBottom:"16px",
                                                            display:"flex",
                                                            width:"100%"}}>
                                                            <div
                                                                //ref={provided.innerRef}
                                                                //{...provided.draggableProps}
                                                                //{...provided.dragHandleProps}
                                                                style={{
                                                                    //...provided.draggableProps.style,
                                                                    display: "flex",
                                                                    marginTop: "15px",
                                                                    marginLeft: "15px",
                                                                    marginBottom: "15px",
                                                                    alignItems:"center",
                                                                    margin:"12px"
                                                                    //...(index === prestaArray.length - 1 ? { marginRight: "15px" } : {}),
                                                                }}
                                                            >
                                                                <img onLoad={handleImageLoad} style={{
                                                                    width: "80px",
                                                                    height: "80px",
                                                                    objectFit: "cover",
                                                                    //boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                                                                    borderRadius: "10px",
                                                                }} className="fade-in" src={bloc?.imageLinks && bloc?.imageLinks?.length > 0
                                                                && bloc?.imageLinks[0]?.imageLink?.length > 0
                                                                    ? bloc?.imageLinks[0]?.imageLink : "https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/image_prestation_par_dfaut.png?alt=media&token=4cb8ec1c-7417-4d93-aa3d-15bc9d0fda0e&_gl=1*1tjyqyy*_ga*OTc3Mjg1MTE0LjE2ODgyMDM2NTU.*_ga_CW55HF8NVT*MTY5NzA0MDcyNy4zMTguMS4xNjk3MDQwODI3LjI4LjAuMA.."} alt={`Image ${index + 1}`} />
                                                                <span style={{display: "-webkit-box",
                                                                    WebkitBoxOrient: "vertical",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    WebkitLineClamp: 4,
                                                                    fontWeight:600,
                                                                    marginLeft: "16px",
                                                                    justifySelf: "center",
                                                                    alignSelf: "center"}}>{bloc?.title}</span>
                                                                {/*{imageUrl.vlink.length > 0 ? (
                                                        <div style={typeIconStyle}>
                                                            <img className="importantwidthheight" style={{ objectFit: "contain", cursor: "pointer" }} src={playIc} alt="Play icon"/>
                                                        </div>
                                                    ) : (
                                                        <div style={typeIconStyle}>
                                                            <img className="importantwidthheight" style={{ objectFit: "contain" }} src={imglogo} alt="Image logo"/>
                                                        </div>
                                                    )}*/}
                                                            </div>
                                                        </div>

                                                    </div>

                                                )}
                                            </Draggable>
                                        ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>


                </DialogContent>
                <DialogActions style={{backgroundColor:"white", marginTop:"0"}}>
                    <div className="button-container" style={{marginBottom:"30px",marginTop:"6px", marginLeft:"30px", marginRight:"30px"}}>
                        <Button onClick={handleReorderBlocClose} className="button-style button-style-noborders" variant="contained">
                            <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                Enregistrer
                            </Typography>
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    )
}