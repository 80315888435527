import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import firebase from "firebase/compat/app";
import {createBloc, getUserSubscription, handleImageUpload} from "../firebase";
import {HeaderHome} from "./headerHome";
import {
    Backdrop,
    CircularProgress,
    TextField,
    Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import ImageCarousel from "./carousel";
import SnackbarWithCustomBackground from "./snackbar";
import {IOSSwitch} from "./iosswitch";
import {serverTimestamp} from "@firebase/firestore";
import {AddMedia} from "./addMedia";
import {ReorderImages} from "./reorderImages";

export default function CreateBloc() {
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        title : "", presentation : "", isBlocPublished:false
    });
    const [tempImages, setTempImages] = useState([]);
    const [tempFiles, setTempFiles] = useState([]);
    const [openReorderImagesDialog, setOpenReorderImagesDialog] = useState(false);
    const [tempReorderImages, setTempReorderImages] = useState([]);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [showBackdrop, setShowBackdrop] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(async (user) => {
            if (!user) {
                navigate("/");
            } else {
                const subObj = await getUserSubscription(user.uid);
                if (subObj?.status==="trialing" || subObj?.status==="active"){
                    //todo nothing ?
                } else {
                    navigateHome();
                }
            }
        });
        // Cleanup the observer when the component is unmounted
        return () => {
            unregisterAuthObserver();
        }
    }, []);
    const navigateHome = () => {
        navigate("/home");
    };

    const navigateToBloc = () => {
        navigate("/bloc");
    };

    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };
    const handleFormPresChanged = (event) => {

        const newText = event.target.value.replace(/\r?\n/g, '\n');
        setFormData({
            ...formData,
            [event.target.name]: newText
        });

    };
    const handleTitleChanged = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handleOpenReorderImagesDialog = () => {
        const deepCopyOfTempImages = tempImages.map(img => ({ ...img }));
        setTempReorderImages(deepCopyOfTempImages);
        setOpenReorderImagesDialog(true);
    };


    const updateFileAndImage = (data) => {
        setTempImages([...tempImages, data.newImageObject]);
        setTempFiles([...tempFiles, data.newFileObject]);
    };

    const handleDeleteImage = (index) => {
        const updatedImages = [...tempImages];
        updatedImages.splice(index, 1);
        const updatedTempFiles = [...tempFiles];
        updatedTempFiles.splice(index, 1);
        setTempFiles(updatedTempFiles);
        setTempImages(updatedImages);
        //This is required to handle the case where a deleted image is added again
        const fileInput = document.getElementById('file-input');
        if (fileInput) {
            fileInput.value = null;
        }
    };

    const handleBlocVisibleChanged = () => {
        setFormData({
            ...formData,
            isBlocPublished: !formData.isBlocPublished
        })
    };

    const onImagesReordered = (array) => {
        setTempImages(array);
    };

    const handleFormSubmit = async () => {

        setShowBackdrop(true);

        if (formData.title.length<2){
            handleOpenSnackbar("Vous devez ajouter un titre.");
            setShowBackdrop(false);
            return;
        }

        if (formData.presentation.length<2){
            handleOpenSnackbar("Vous devez ajouter une présentation / description pour votre bloc.");
            setShowBackdrop(false);
            return;
        }

        let imagesForDb = [];
        for (let image of tempImages){
            for (let file of tempFiles){
                if (file.date===image.date){
                    let newLink = "";
                    //file logic
                    if (file){
                        try {
                            //todo modify this function to upload images to a repo that matches bloc like /bloc - danke
                            newLink = await handleImageUpload(file.file, true);
                        } catch (e){
                            handleOpenSnackbar("Quelques chose s'est mal passé lors du chargement de vos images, contactez nous => ", e.message);
                            setShowBackdrop(false);
                            return;
                        }
                    }
                    imagesForDb.push({
                        imageLink : newLink,
                        vlink : file.vlink,
                        date : file.date
                    })
                }
            }
        }

        let dbObject = {
            timestamp: serverTimestamp(),
            imageLinks: imagesForDb,
            title : formData.title,
            description : formData.presentation,
            uid : firebase.auth().currentUser.uid,
            isBlocPublished : formData.isBlocPublished,
            blocDeleted : false
        }

        try {
           await createBloc(dbObject);
        } catch (e) {
            console.error("error when uploading data to db => ", e);
            setShowBackdrop(false);
            handleOpenSnackbar("Quelques chose s'est mal passé, contactez nous ", e);
            return;
        }
        setShowBackdrop(false);
        navigate("/bloc");
    }

    return (
        <div className="sub-container">
            <HeaderHome></HeaderHome>
            <div className="sub-container-no-top">
                <span className="title-style">Créer un bloc d’infos</span>
                <div className="field-container">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Titre
                                  </span>
                    </div>
                    <TextField className="field-style" id="outlined-basic" label="Ecrire" variant="outlined" value={formData.title}
                               onChange={handleTitleChanged} name="title"/>
                </div>
                <div className="field-container-b">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Présentation
                                  </span>
                    </div>
                    <TextField className="field-style" variant="outlined" value={formData.presentation}
                           multiline
                           maxRows={12}
                           label="Ecrire"
                           onChange={handleFormPresChanged} name="presentation"/>
                </div>
                <div className="field-container">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Ajouter un média
                                  </span>
                    </div>
                </div>
                <div style={{ textAlign:"center", color:"white", display:"flex", alignSelf:"start", alignContent:'start', alignItems:'start', justifyContent:'center', marginTop:"15px"}}>
                    <AddMedia onNewMediaAdded={updateFileAndImage}/>
                    <ReorderImages onImagesReordered={onImagesReordered} tempImages={tempImages}></ReorderImages>
                </div>
                <div style={{marginTop:"15px", display:"flex", width:"100%"}}>
                    {tempImages?.length<1 ?
                        <div>Aucun média séléctionné</div> :  <ImageCarousel style={{marginTop:"10px"}} imagesArray={tempImages} onDeleteImage={handleDeleteImage}/>}
                </div>

                <div className="field-container">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Statut de publication
                                  </span>
                    </div>
                </div>
                <div style={{ marginTop:"10px" ,width:"100%", display:"flex", flexDirection:"row", justifyContent:"start", alignItems:"center"}}>

                    <IOSSwitch
                        checked={formData.isBlocPublished}
                        onChange={(event) => handleBlocVisibleChanged(event)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />

                    {formData.isBlocPublished ? <span style={{marginLeft:"20px", color:"black"}}>Publié</span>
                        : <span style={{marginLeft:"20px", color:"black"}}>Brouillon</span>}

                </div>

                {formData.isBlocPublished===false ? (
                    <div className="button-container" style={{marginTop:"70px"}}>
                        <Button onClick={() => handleFormSubmit()} className="blue-button button-style-noborders" variant="contained">
                            <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                Enregistrer
                            </Typography>
                        </Button>
                    </div>
                ) : (
                    <div className="button-container" style={{marginTop:"70px"}}>
                        <Button onClick={() => handleFormSubmit()} className="button-style button-style-noborders" variant="contained">
                            <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                Enregistrer et publier
                            </Typography>
                        </Button>
                    </div>
                )}

                <div className="button-container" style={{marginTop:"15px"}}>
                    <Button style={{marginBottom:"100px"}} className="button-style-nogr button-style-borders" disableElevation variant="contained" onClick={navigateToBloc}>
                        <Typography variant="button" style={{ textTransform: 'none' }}>
                            Annuler
                        </Typography>
                    </Button>
                </div>

            </div>

            <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar}
                                          message={snackMessage}/>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}