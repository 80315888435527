import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import firebase from "firebase/compat/app";
import {
    createBloc,
    deleteImageByLink,
    getBlocDoc,
    getUserSubscription,
    handleImageUpload,
    updateBloc, updatePresta
} from "../firebase";
import {serverTimestamp} from "@firebase/firestore";
import {HeaderHome} from "./headerHome";
import {
    Backdrop,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Skeleton,
    TextField,
    Typography, useMediaQuery, useTheme
} from "@mui/material";
import {AddMedia} from "./addMedia";
import {ReorderImages} from "./reorderImages";
import ImageCarousel from "./carousel";
import {IOSSwitch} from "./iosswitch";
import Button from "@mui/material/Button";
import SnackbarWithCustomBackground from "./snackbar";
import {useParams} from "react-router";

export default function ModifyBloc() {

    const {pathname} = useLocation();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        title : "", presentation : "", isBlocPublished:false
    });
    const { id } = useParams();

    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const [tempImages, setTempImages] = useState([]);
    const [tempFiles, setTempFiles] = useState([]);
    const [openReorderImagesDialog, setOpenReorderImagesDialog] = useState(false);
    const [tempReorderImages, setTempReorderImages] = useState([]);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [imageLinks, setImageLinks] = useState([]);
    const [userBloc, setUserBloc] = useState({});
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [linksToDelete, setLinksToDelete] = useState([]);
    const [isDelDialogOpen, setIsDelDialogOpen] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(async (user) => {
            if (!user) {
                navigate("/");
            } else {
                const subObj = await getUserSubscription(user.uid);
                if (subObj?.status==="trialing" || subObj?.status==="active"){
                    if (id){
                        const userBloc = await getBlocDoc(id);
                        setUserBloc(userBloc);
                        setFormData({
                            title: userBloc.title,
                            presentation : userBloc.description,
                            isBlocPublished: userBloc.isBlocPublished
                        })
                        const goodFormatTempImages = [];
                        for (let image of userBloc?.imageLinks){
                            goodFormatTempImages.push(
                                {
                                    imageLink : image.imageLink,
                                    date : new Date(),
                                    vlink: image.vlink
                                }
                            )
                        }
                        const newImageArray = tempImages.concat(goodFormatTempImages);
                        setTempImages(newImageArray);
                        setShowSkeleton(false);
                    } else {
                        setShowSkeleton(false);
                        //todo show something about page expired
                    }
                } else {
                    navigateHome();
                }
            }
        });
        // Cleanup the observer when the component is unmounted
        return () => {
            unregisterAuthObserver();
        }
    }, []);
    const navigateHome = () => {
        navigate("/home");
    };

    const navigateToBloc = () => {
        navigate("/bloc");
    };

    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };
    const handleFormPresChanged = (event) => {

        const newText = event.target.value.replace(/\r?\n/g, '\n');
        setFormData({
            ...formData,
            [event.target.name]: newText
        });

    };
    const handleTitleChanged = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handleOpenReorderImagesDialog = () => {
        const deepCopyOfTempImages = tempImages.map(img => ({ ...img }));
        setTempReorderImages(deepCopyOfTempImages);
        setOpenReorderImagesDialog(true);
    };


    const updateFileAndImage = (data) => {
        setTempImages([...tempImages, data.newImageObject]);
        setTempFiles([...tempFiles, data.newFileObject]);
    };

    const handleBlocVisibleChanged = () => {
        setFormData({
            ...formData,
            isBlocPublished: !formData.isBlocPublished
        })
    };

    const onImagesReordered = (array) => {
        setTempImages(array);
    };

    const handleDeleteImage = async (index) => {
        if (tempImages[index].imageLink.includes("firebasestorage")){
            setLinksToDelete([
                ...linksToDelete,
                tempImages[index].imageLink
            ]);
        }

        const updatedTempFiles = tempFiles.filter((file) =>
            tempImages.some((image) => file?.date === image?.date)
        ).map((file) => ({ ...file }));
        setTempFiles(updatedTempFiles);

        const updatedImages = [...tempImages];
        updatedImages.splice(index, 1);
        setTempImages(updatedImages);

        //This is required to handle the case where a deleted image is added again
        const fileInput = document.getElementById('file-input');
        if (fileInput) {
            fileInput.value = null;
        }
    };

    const handleFormSubmit = async () => {

        setShowBackdrop(true);

        if (formData.title.length<2){
            handleOpenSnackbar("Vous devez ajouter un titre.");
            setShowBackdrop(false);
            return;
        }

        if (formData.presentation.length<2){
            handleOpenSnackbar("Vous devez ajouter une présentation / description pour votre bloc.");
            setShowBackdrop(false);
            return;
        }

        let imagesForDb = [];
        for (let image of tempImages){
            if (image.imageLink.includes("firebasestorage")){
                imagesForDb.push({
                    imageLink : image.imageLink,
                    vlink : image.vlink,
                    date : image.date
                });
            } else {
                for (let file of tempFiles){
                    if (file.date===image.date){
                        let newLink = "";
                        //file logic
                        if (file){
                            try {
                                //todo modify this function to upload images to a repo that matches bloc like /bloc - danke
                                newLink = await handleImageUpload(file.file, true);
                            } catch (e){
                                handleOpenSnackbar("Quelques chose s'est mal passé lors du chargement de vos images, contactez nous => ", e.message);
                                setShowBackdrop(false);
                                return;
                            }
                        }
                        imagesForDb.push({
                            imageLink : newLink,
                            vlink : file.vlink,
                            date : file.date
                        })
                    }
                }
            }
        }

        let dbObject = {
            timestamp: serverTimestamp(),
            imageLinks: imagesForDb,
            title : formData.title,
            description : formData.presentation,
            isBlocPublished : formData.isBlocPublished
        }

        try {
            await updateBloc(dbObject, userBloc?.documentId);
        } catch (e) {
            console.error("error when uploading data to db => ", e);
            setShowBackdrop(false);
            handleOpenSnackbar("Quelques chose s'est mal passé, contactez nous ", e);
            return;
        }

        try {
            if (linksToDelete){
                for (let link of linksToDelete){
                    await deleteImageByLink(link);
                }
            }
        } catch (e) {
            console.error("Error deleting image ", e);
            setShowBackdrop(false);
            handleOpenSnackbar("Quelque chose s'est mal passé", e);
            return;
        }

        setShowBackdrop(false);
        navigate("/bloc");
    }

    const handleDelDialogClose = () => {
        setIsDelDialogOpen(false);
    };

    const handleBlocDelete = async () => {
        setShowBackdrop(true);
        try {
            await updateBloc({blocDeleted:true}, id)
        } catch (e) {
            console.log(e.message);
            handleOpenSnackbar("Quelque chose s'est mal passé, réessayez ou contactez nous.");
            setShowBackdrop(false);
            return;
        }
        setIsDelDialogOpen(false);
        setShowBackdrop(false);
        navigate("/bloc");
    };

    return (
        <div className="sub-container">
            <HeaderHome></HeaderHome>
            {showSkeleton ? (
                <div className="sub-container-no-top">
                    <div style={{
                        display: "flex", width: "100%", flexDirection: "column",
                        justifyContent: "center", alignContent: "center", alignItems: "center"
                    }}>
                        <Skeleton style={{borderRadius: "15px", minWidth:"150px"}} variant="text" sx={{fontSize: '2rem'}}
                                  width="20%"/>
                        <Skeleton style={{marginTop: "50px", borderRadius: "15px"}} variant="rectangular"
                                  width="100%" height={150}/>
                    </div>
                </div>
            ) : (
                <div className="sub-container-no-top">
                    <span className="title-style">Modifier le bloc d’infos</span>
                    <div className="field-container">
                        <div className="title-field-container">
                                  <span className="text-field-style">
                                      Titre
                                  </span>
                        </div>
                        <TextField className="field-style" id="outlined-basic" label="Ecrire" variant="outlined" value={formData.title}
                                   onChange={handleTitleChanged} name="title"/>
                    </div>
                    <div className="field-container-b">
                        <div className="title-field-container">
                                  <span className="text-field-style">
                                      Présentation
                                  </span>
                        </div>
                        <TextField className="field-style" variant="outlined" value={formData.presentation}
                                   multiline
                                   maxRows={12}
                                   label="Ecrire"
                                   onChange={handleFormPresChanged} name="presentation"/>
                    </div>
                    <div className="field-container">
                        <div className="title-field-container">
                                  <span className="text-field-style">
                                      Ajouter un média
                                  </span>
                        </div>
                    </div>
                    <div style={{ textAlign:"center", color:"white", display:"flex", alignSelf:"start", alignContent:'start', alignItems:'start', justifyContent:'center', marginTop:"15px"}}>
                        <AddMedia onNewMediaAdded={updateFileAndImage}/>
                        <ReorderImages onImagesReordered={onImagesReordered} tempImages={tempImages}></ReorderImages>
                    </div>
                    <div style={{marginTop:"15px", display:"flex", width:"100%"}}>
                        {tempImages?.length<1 ?
                            <div>Aucun média séléctionné</div> :  <ImageCarousel style={{marginTop:"10px"}} imagesArray={tempImages} onDeleteImage={handleDeleteImage}/>}
                    </div>

                    <div className="field-container">
                        <div className="title-field-container">
                                  <span className="text-field-style">
                                      Statut de publication
                                  </span>
                        </div>
                    </div>
                    <div style={{ marginTop:"10px" ,width:"100%", display:"flex", flexDirection:"row", justifyContent:"start", alignItems:"center"}}>

                        <IOSSwitch
                            checked={formData.isBlocPublished}
                            onChange={(event) => handleBlocVisibleChanged(event)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />

                        {formData.isBlocPublished ? <span style={{marginLeft:"20px", color:"black"}}>Publié</span>
                            : <span style={{marginLeft:"20px", color:"black"}}>Brouillon</span>}

                    </div>

                    {formData.isBlocPublished===false ? (
                        <div className="button-container" style={{marginTop:"70px"}}>
                            <Button onClick={() => handleFormSubmit()} className="blue-button button-style-noborders" variant="contained">
                                <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                    Enregistrer
                                </Typography>
                            </Button>
                        </div>
                    ) : (
                        <div className="button-container" style={{marginTop:"70px"}}>
                            <Button onClick={() => handleFormSubmit()} className="button-style button-style-noborders" variant="contained">
                                <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                    Enregistrer et publier
                                </Typography>
                            </Button>
                        </div>
                    )}

                    <div className="button-container" style={{marginTop:"15px"}}>
                        <Button style={{marginBottom:"40px"}} className="button-style-nogr button-style-borders" disableElevation variant="contained" onClick={navigateToBloc}>
                            <Typography variant="button" style={{ textTransform: 'none' }}>
                                Annuler
                            </Typography>
                        </Button>
                    </div>

                    <div className="button-container" style={{marginTop:"40px"}}>
                        <Button style={{marginBottom:"40px"}} className="button-style-nogr button-style-noborders" disableElevation variant="contained" onClick={() => setIsDelDialogOpen(true)}>
                            <Typography variant="button" style={{ textTransform: 'none', textDecoration: "underline" }}>
                                Supprimer
                            </Typography>
                        </Button>
                    </div>

                </div>
            )}


            <Dialog open={isDelDialogOpen} onClose={handleDelDialogClose}
                    fullWidth={!isLargeScreen}
                    PaperProps={{
                        style: {
                            minWidth: isLargeScreen ? '500px' : 'auto',
                            maxWidth: isLargeScreen ? '500px' : 'auto'
                        },
                    }}>
                <DialogContent style={{backgroundColor:"white"}}>
                    <div style={{display:"flex", flexDirection:"column", alignItems:"start"}}>
                        <span style={{fontSize:"16px", fontWeight:700, marginTop:"30px"}}>Supprimer ce bloc</span>
                        <span style={{fontSize:"16px", marginTop:"30px"}}>Les données ne seront plus accessibles.</span>
                    </div>
                </DialogContent>
                <DialogActions style={{backgroundColor:"white"}}>
                    <div className="button-container" style={{marginBottom:"30px",marginTop:"6px", marginLeft:"30px", marginRight:"30px"}}>
                        <Button disableElevation onClick={handleBlocDelete} className="button-style-nogr button-style-borders" variant="contained">
                            <Typography variant="button" style={{ textTransform: 'none', color:'black' }}>
                                Supprimer
                            </Typography>
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>

            <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar}
                                          message={snackMessage}/>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}