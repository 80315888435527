import React from "react";
import {HeaderHome} from "./headerHome";

export const SomethingWentWrong = () => {
    return (
        <div className="sub-container">
            <HeaderHome></HeaderHome>
            <div className="sub-container-no-top">
                <div style={{display: "flex", width:"80%", padding: "20px", flexDirection: "column", justifyContent: "center", marginTop:"30px",
                    alignItems: "center", borderRadius: "15px", background: "#FFF", boxShadow:'0px 4px 15px 0px rgba(218, 224, 229, 0.90)'}}>
                                            <span style={{lineHeight: "24px"}}>
                                                Quelque chose s'est mal passé 🤷<br/>Actualisez la page ou contactez nous
                                            </span>
                </div>
            </div>
        </div>
    )
}