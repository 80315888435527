import firebase from "firebase/compat/app";
import React, {useEffect, useState} from "react";
import {getUserBlocs} from "../firebase";
import SnackbarWithCustomBackground from "./snackbar";
import {useMediaQuery, useTheme} from "@mui/material";
import {useNavigate} from "react-router-dom";
export const BlocsSlider = () => {

    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const navigate = useNavigate();
    const uid = firebase.auth().currentUser.uid;
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [userBloc, setUserBlocs] = useState([]);
    const [isProdPresent, setIsProdPresent] = useState(false);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const ub = await getUserBlocs(uid);
                setIsProdPresent(ub.some(item => item.isBlocPublished === true))
                setUserBlocs(ub);
            } catch (e) {
                console.error(e);
                handleOpenSnackbar("Quelque chose s'est mal passé lors du chargements des blocs d'info.");
            }
        }
        fetchData();
    }, []);

    const handleImageLoad = (event) => {
        event.target.classList.add('loaded'); // Add the 'loaded' class to trigger fade-in
    };

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };
    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };

    const navigateToViewBloc = (id) => {
        navigate("/viewbloc/"+id);
    };

    return (
        <>
            {isProdPresent ? (
                <div
                    style={{
                        // Outer container is 80% wide and centered
                        display: "flex",
                        width: "100%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: "46px",
                        marginBottom: "0px",
                        position: "relative",
                        overflowX: "visible", // Allows partial overflow to be visible
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "102vw",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            marginLeft: isLargeScreen ? undefined : "-10vw",
                            marginRight: isLargeScreen ? undefined : "-10vw",
                            overflowX: "auto", // Enables scrolling
                            scrollSnapType: "x mandatory",
                            scrollbarWidth: "none", // Optional: Hides Firefox scrollbar
                        }}
                    >
                        {userBloc
                            .filter((elem) => elem.isBlocPublished)
                            .sort((a, b) => {
                                if (!a.isSorted || !b.isSorted) {
                                    if (!a.isSorted && b.isSorted) return 1; // b is sorted, a is not
                                    if (a.isSorted && !b.isSorted) return -1; // a is sorted, b is not

                                    // Both are not sorted; sort by timestamp (descending)
                                    return (
                                        new Date(b.timestamp.seconds * 1000) -
                                        new Date(a.timestamp.seconds * 1000)
                                    );
                                }
                                // Both are sorted; compare by sortIndex
                                return a.sortIndex - b.sortIndex;
                            })
                            .map((bloc, index, array) => (
                                <div
                                    key={index}
                                    onClick={() => navigateToViewBloc(bloc.documentId)}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        cursor: "pointer",
                                        marginLeft: index === 0 ? "21px" : "6px",
                                        overflowY: "visible",
                                        paddingRight: index === array.length - 1 ? "11px" : undefined,
                                    }}
                                >
                                    <div style={{ overflowY: "visible" }}>
                                        <img
                                            onLoad={handleImageLoad}
                                            style={{
                                                width: "100px",
                                                height: "100px",
                                                margin: "4px",
                                                borderRadius: "20px",
                                                objectFit: "cover",
                                                scrollSnapAlign: "center",
                                                boxShadow: "0px 4px 14px 0px rgba(16, 39, 54, 0.25)",
                                                flexShrink: 0,
                                            }}
                                            className="fade-in"
                                            src={
                                                bloc?.imageLinks &&
                                                bloc.imageLinks.length > 0 &&
                                                bloc.imageLinks[0]?.imageLink?.length > 0
                                                    ? bloc.imageLinks[0].imageLink
                                                    : "https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/image_prestation_par_dfaut.png?alt=media&token=4cb8ec1c-7417-4d93-aa3d-15bc9d0fda0e&_gl=1*1tjyqyy*_ga*OTc3Mjg1MTE0LjE2ODgyMDM2NTU.*_ga_CW55HF8NVT*MTY5NzA0MDcyNy4zMTguMS4xNjk3MDQwODI3LjI4LjAuMA.."
                                            }
                                            alt={`Bloc image ${index}`}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            marginTop: "8px",
                                            marginLeft: "6px",
                                            width: "100px",
                                            textOverflow: "ellipsis",
                                            textAlign: "center",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                        }}
                                    >
                                        {bloc?.title}
                                    </div>
                                </div>
                            ))}
                    </div>

                    {/* Example Snackbar */}
                    <SnackbarWithCustomBackground
                        isOpen={showSnackbar}
                        onClose={handleCloseSnackbar}
                        message={snackMessage}
                        style={{ width: "100%" }}
                    />
                </div>
            ) : (
                <div />
            )}
        </>
    );
}