import Button from "@mui/material/Button";
import {Dialog, DialogActions, DialogContent, TextField, Typography, useMediaQuery, useTheme} from "@mui/material";
import React, {useState} from "react";
import IconButton from "@mui/material/IconButton";
import trashic from "../assets/trashic.svg";
import SnackbarWithCustomBackground from "./snackbar";

const deleteButtonStyle = {
    position: 'absolute',
    top: '5px',
    right: '5px',
    backgroundColor: '#F2F2F2',
    opacity:'70%',
    fontSize: '1.5rem',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};
export const AddMedia = ({ onNewMediaAdded }) => {

    const theme = useTheme();
    const [tempPopImage, setTempPopImage] = useState(null);
    const [tempPopFile, setTempPopFile] = useState(null);
    const [videoLink, setVideoLink] = useState('');
    const [isVideoDialogOpen, setIsVideoDialogOpen] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

    const handleFileChange = (event) => {
        const newfile = event.target.files[0];
        const date = new Date();
        const newFileObject = {
            file: newfile,
            date: date,
            vlink: "",
        };
        if (newfile) {
            const reader = new FileReader();
            reader.onload = () => {
                // reader.result contains the data URL
                // reader.result contains the data URL
                // reader.result contains the data URL
                const newImageObject = {
                    imageLink: reader.result,
                    date: date,
                    vlink: "",
                };

                onNewMediaAdded({newImageObject:newImageObject, newFileObject:newFileObject});
            };
            reader.readAsDataURL(newfile);
        }
    };

    const handlePopFileChange = (event) => {
        const newfile = event.target.files[0];
        const date = new Date();
        const newFileObject = {
            file: newfile,
            date: date,
            vlink: "",
        };
        if (newfile) {
            const reader = new FileReader();
            reader.onload = () => {
                // reader.result contains the data URL
                // reader.result contains the data URL
                // reader.result contains the data URL
                const newImageObject = {
                    imageLink: reader.result,
                    date: date,
                    vlink: "",
                };

                setTempPopImage(newImageObject);
                setTempPopFile(newFileObject);
            };
            reader.readAsDataURL(newfile);
        }
    };

    const handleAddVideoLink = () => {
        if (videoLink?.length>0 && tempPopImage?.imageLink?.length>0 && tempPopFile){
            const updatedTempPopImage = {...tempPopImage, vlink:videoLink};
            const updatedTempPopFile = {...tempPopFile, vlink:videoLink};
            onNewMediaAdded({newImageObject:updatedTempPopImage, newFileObject:updatedTempPopFile});
            setIsVideoDialogOpen(false);
        } else {
            handleOpenSnackbar("Vous devez ajouter un lien vers une vidéo et une image pour enregistrer.");
        }
    };


    const handleVideoDialogOpen = () => {
        setVideoLink("");
        setTempPopImage({});
        setTempPopFile({});
        setIsVideoDialogOpen(true);
    };

    const handleVideoDialogClose = () => {
        setIsVideoDialogOpen(false);
    };

    const handleDeletePopImage = (index) => {
        setTempPopImage({});
        setTempPopFile({});
    }

    const handleVideoLinkChange = (event) => {
        // Update the link state when the input field changes
        setVideoLink(event.target.value);
    };

    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    return (
        <div>
            <Button disableElevation={true} component="label" className="button-style-noborders-lil" variant="contained" style={{backgroundColor:"black", color:"white"}}>
                <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px" }}>
                    Image
                </Typography>
                <input
                    id="file-input" style={{ display: 'none', width:"100%" }} type="file"
                    accept="image/jpeg,image/png,image/gif,image/svg+xml" onChange={handleFileChange} />
            </Button>
            <Button disableElevation={true} component="label" className="button-style-noborders-lil"
                    variant="contained" onClick={handleVideoDialogOpen}
                    style={{backgroundColor:"black", color:"white", marginLeft:"10px"}}>
                <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px"}}>
                    Vidéo
                </Typography>
            </Button>
            <Dialog
                open={isVideoDialogOpen}
                onClose={handleVideoDialogClose}
                fullWidth={!isLargeScreen}
                PaperProps={{
                    style: {
                        minWidth: isLargeScreen ? '500px' : 'auto',
                        maxWidth: isLargeScreen ? '500px' : 'auto',
                    },
                }}
            >
                <DialogContent style={{ backgroundColor: "white" }}>
                    <div style={{display:"flex", flexDirection:"column"}}>
                        <span style={{fontWeight:700, fontSize:"16px"}}>
                            Ajouter une image de couverture
                        </span>
                        <div style={{marginTop:"15px"}}>
                            <Button disableElevation={true} component="label" className="button-style-noborders-lil" variant="contained" style={{backgroundColor:"black", color:"white"}}>
                                <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px" }}>
                                    Insérer
                                </Typography>
                                <input
                                    id="file-input" style={{ display: 'none', width:"100%" }} type="file"
                                    accept="image/jpeg,image/png,image/gif,image/svg+xml" onChange={handlePopFileChange} />
                            </Button>
                        </div>
                        {tempPopImage?.imageLink && (
                            <div style={{width:"50%", marginTop:"20px"}}>
                                <div className="image-wrapper-pop">
                                    <img src={tempPopImage?.imageLink} alt={`pop`} />
                                    <IconButton
                                        style={deleteButtonStyle}
                                        onClick={() => handleDeletePopImage()}
                                    >
                                        <img src={trashic} style={{width:"20px", height:"20px"}}/>
                                    </IconButton>
                                </div>
                            </div>
                        )}
                        <span style={{fontWeight:700, fontSize:"16px", marginTop:"30px"}}>
                            Url de la vidéo
                        </span>

                        <TextField style={{marginTop:"10px"}} onChange={handleVideoLinkChange} className="field-style"
                                   label="Copiez/collez votre lien" variant="outlined" multiline={true} />
                    </div>
                </DialogContent>
                <DialogActions style={{ backgroundColor: "white" }}>
                    <Button className="blue-button button-style-noborders" style={{marginLeft:"32px", marginRight:"32px", marginBottom:"30px", marginTop:"10px", backgroundColor:"black"}} variant="button"
                            onClick={() => {
                                handleAddVideoLink(); // Replace indexToDelete with the actual index you want to delete
                            }}
                    >
                        <Typography variant="button" style={{textTransform: 'none', color:"white"}}>
                            Confirmer
                        </Typography>
                    </Button>
                </DialogActions>
            </Dialog>
            <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar}
                                          message={snackMessage} style={{width: "100%"}}/>
        </div>
    )

}